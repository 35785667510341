import React, {Component} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';

interface Props {
    img: string,
    name: string,
}
interface State {}
export default class Avatar extends Component<Props, State> {

    img_style_pc_S = {
        width: "300px",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
    } as React.CSSProperties;

    img_style_pc_A = {
        width: "25vw",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
    } as React.CSSProperties;

    img_style_mobile = {
        width: "25vw",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
        marginBottom: "-1vw"
    } as React.CSSProperties;

    div_style_mobile = {
        textAlign: "center",
    } as React.CSSProperties;

    render() {
        
        return (
            <div>
                <MediaQuery query="(max-width: 650px)">
                    <div style={this.div_style_mobile}>
                        <img style={this.img_style_mobile} src={this.props.img}/>
                        <p style={{fontWeight: "bold"}}>{this.props.name}</p>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 651px) and (max-width: 1000px)">
                    <img style={this.img_style_pc_A} src={this.props.img}/>
                    <p style={{fontWeight: "bold"}}>{this.props.name}</p>
                </MediaQuery>
                <MediaQuery query="(min-width: 1001px)">
                    <img style={this.img_style_pc_S} src={this.props.img}/>
                    <p style={{fontWeight: "bold"}}>{this.props.name}</p>
                </MediaQuery>
            </div>
        
        )
    }
}