import React, { Component, useEffect } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import owner from "../static/owner.svg";
import customer from "../static/customer.svg";
import Avatar from "./Avatar";
import Arrow from "./Arrow";
import { motion, useAnimation, Variants } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Intro = () => {
  /* ------------------- アニメーション系 ------------------- */
  const controls = useAnimation();
  let { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const h3Variants = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  } as Variants;

  /* ------------------- アニメーション系 ------------------- */

  /* ------------------------ UI系 ------------------------ */

  const div_style = {
    textAlign: "center",
  } as React.CSSProperties;

  const div_style_image_pc = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties;

  const div_style_image_mobile = {
    display: "flex",
    flexDirection: "column",
  } as React.CSSProperties;

  /* ------------------------ UI系 ------------------------ */

  // render() {
  return (
    <motion.section ref={ref} animate={controls}>
      <div style={div_style} id="1">
        <h3>cachetteとは？</h3>
        <p className="sp">
          cachetteはお店の出店・予約管理ができるアプリです。
          <br />
          アプリの利用者はお店の出店と予約ができます。
          <br />
          自分だけのお店=カシェットを出店でき、
          <br />
          カシェット毎にQRコードが発行できます。
          <br />
          予約は発行されたQRコードからのみ可能です。
          <br />
          cachetteはフランス語で「隠れ家」という意味です。
          <br />
          このアプリはそんな自分だけが知っているカシェットを
          <br />
          予約できるアプリとなっております。
        </p>
        <MediaQuery query="(max-width: 650px)">
          <div style={div_style_image_mobile}>
            <motion.div
              style={{ paddingLeft: "40vw", marginBottom: "-8vw" }}
              variants={h3Variants}
            >
              <Avatar img={owner} name="出店した人" />
            </motion.div>
            <Arrow />
            <motion.div
              style={{ paddingRight: "40vw", marginBottom: "-8vw" }}
              variants={h3Variants}
            >
              <Avatar img={customer} name="予約する人" />
            </motion.div>
          </div>
        </MediaQuery>
        <MediaQuery query="(min-width: 651px)">
          <div style={div_style_image_pc}>
            <motion.div variants={h3Variants}>
              <Avatar img={customer} name="予約する人" />
            </motion.div>
            <Arrow />
            <motion.div variants={h3Variants}>
              <Avatar img={owner} name="出店した人" />
            </motion.div>
          </div>
        </MediaQuery>
      </div>
    </motion.section>
  );
  // }
};

export default Intro;
