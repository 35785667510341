import React, {Component} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import AppStore from '../static/AppStore.svg'
import { motion, useAnimation, Variants } from 'framer-motion';

export default class AppButton extends Component {

    div_style_pc = {
        display: "flex",
        flexDirection: "column"
    } as React.CSSProperties;

    div_style_mobile = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
    } as React.CSSProperties;

    render() {
        return (
            <div>
                <MediaQuery query="(max-width: 800px)">
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: 0.9}}
                        style={this.div_style_mobile}>
                        <a href='https://apps.apple.com/us/app/cachette/id1565488450'>
                            <img style={{marginTop: "3vw", height: "10vw", marginBottom: "5vw", marginLeft: "4vw"}} src={AppStore} />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=jp.JShiota.cachette'>
                            <img style={{marginTop: "3vw", height: "15vw", marginBottom: "5vw", marginLeft: "3vw"}} alt='Google Play で手に入れよう' src='https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png' />
                        </a>
                    </motion.div>
                </MediaQuery>
                <MediaQuery query="(min-width: 801px)">
                    <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.3, delay: 0.6}}
                        style={this.div_style_pc}>
                        <a href='https://apps.apple.com/us/app/cachette/id1565488450'>
                            <img style={{marginTop: "10px", width: "207px", marginLeft: "14px"}} src={AppStore} />
                        </a>
                        <a href='https://play.google.com/store/apps/details?id=jp.JShiota.cachette'>
                            <img style={{marginTop: "0", width: "236px"}} alt='Google Play で手に入れよう' src='https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png' />
                        </a>
                    </motion.div>
                </MediaQuery>
            </div>
        );
    }
}