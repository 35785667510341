import React, { Component, useEffect } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import shop from "../static/shop.svg";
import notshop from "../static/notshop.svg";
import online from "../static/online.svg";
import UseCard from "./UseCard";
import { motion, useAnimation, Variants } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Use = () => {
  /* ------------------- アニメーション系 ------------------- */
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const Variants1 = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: 0.3,
      },
    },
  } as Variants;

  const Variants2 = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: 0.6,
      },
    },
  } as Variants;

  const Variants3 = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: 0.9,
      },
    },
  } as Variants;
  /* ------------------- アニメーション系 ------------------- */

  /* ------------------------ UI系 ------------------------ */
  const div_style = {
    textAlign: "center",
  } as React.CSSProperties;

  const div_style1 = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  } as React.CSSProperties;
  /* ------------------------ UI系 ------------------------ */

  // render() {
  return (
    <motion.section ref={ref} animate={controls} style={div_style} id="3">
      <h3>利用シーン</h3>
      <div style={div_style1}>
        <motion.div variants={Variants1}>
          <UseCard
            img={shop}
            title="店舗があるお店"
            text="カフェ・美容室・整体院・居酒屋・エステ・お料理教室・会員制ラウンジ・写真館 など"
          />
        </motion.div>
        <motion.div variants={Variants2}>
          <UseCard
            img={notshop}
            title="店舗がないお店"
            text="ベビーシッター・家庭教師・レンタル〇〇・ハウスクリーニング・家政婦・何でも屋 など"
          />
        </motion.div>
        <motion.div variants={Variants3}>
          <UseCard
            img={online}
            title="オンラインのお店"
            text="英会話・パソコン教室・カウンセリング・占い・セミナー・オンラインサロン など"
          />
        </motion.div>
      </div>
    </motion.section>
  );
  // }
};
export default Use;
