import React, {Component} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import ReserveCard from './ReserveCard';

export default class Reserve extends Component {

    div_style = {
        textAlign: "center",
        marginTop: "-1vw"
    } as React.CSSProperties;

    div_style1 = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly"
    } as React.CSSProperties;

    h2_style_pc = {
        fontSize: "4vw"
    }

    h2_style_mobile = {
        marginTop: "0vw",
        fontSize: "5.5vw"
    }

    render() {
        return (
            <div style={this.div_style}>
                <div style={this.div_style1}><ReserveCard/></div>
            </div>
        );
    }
}