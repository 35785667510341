import React, {Component, useEffect} from 'react';
import Intro from './Intro';
import Invite from './Invite';
import Open from './Open';
import Reserve from './Reserve';
import Top from './Top';
import Footer from './Footer';
import Use from './Use';
import Header from './Header';
    
export default class Home extends Component {

    state = {
        loading: true
    }

    render() {
        setTimeout(() => {
            this.setState({loading: false})
        }, 1000);
        if (this.state.loading) {
            return (
                <div className="spinnerDiv">
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className="backimg">
                    
                    <Header/>
                    <Top/>
                    <br/>
                    <Intro/>    
                    <br/>
                    <br/>
                    <Open/>
                    <br/>
                    <br/>
                    {/* <Reserve/>
                    <br/>
                    <br/> */}
                    <Use/>
                    <br/>
                    <br/>
                    <Invite/>
                    <Footer/>
                </div>
            );
        }
    }
}