import React, { Component, useEffect } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import left from "../static/left.svg";
import right from "../static/right.svg";
import { motion, useAnimation, Variants } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Arrow = () => {
  /* ------------------- アニメーション系 ------------------- */
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const uppVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        delay: 0.8,
      },
    },
  } as Variants;

  const downpVariants = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.4,
        delay: 1.6,
      },
    },
  } as Variants;

  const rightVariants_pc = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        delay: 1.2,
      },
    },
  } as Variants;

  const leftVariants_pc = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        delay: 0.4,
      },
    },
  } as Variants;

  const rightVariants_mobile = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        delay: 0.4,
      },
    },
  } as Variants;

  const leftVariants_mobile = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.4,
        delay: 1.2,
      },
    },
  } as Variants;
  /* ------------------- アニメーション系 ------------------- */

  /* ------------------------ UI系 ------------------------ */
  const div_style_pc_S = {
    textAlign: "center",
    padding: "30px",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  } as React.CSSProperties;

  const div_style_pc_A = {
    textAlign: "center",
    padding: "1vw",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
  } as React.CSSProperties;

  const div_style_mobile = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  } as React.CSSProperties;

  const img_style_pc = {
    width: "20vw",
    overflow: "hidden",
  } as React.CSSProperties;
  /* ------------------------ UI系 ------------------------ */

  // render() {

  return (
    <motion.section ref={ref} animate={controls}>
      <MediaQuery query="(max-width: 464px)">
        <div style={div_style_mobile}>
          <motion.p
            style={{
              marginTop: "-40px",
              marginRight: "-40px",
              fontWeight: "bold",
              fontSize: "12px",
            }}
            variants={uppVariants}
          >
            カシェットのQRコードを発行
          </motion.p>
          <div
            style={{
              width: "25vw",
              transform: "rotate(120deg)",
              marginRight: "-10vw",
              overflow: "hidden",
            }}
          >
            <motion.img
              src={right}
              variants={rightVariants_mobile}
              style={{ width: "23vw", transform: "rotate(120deg)" }}
            />
          </div>
          <div
            style={{
              width: "25vw",
              transform: "rotate(120deg)",
              marginLeft: "-10vw",
              overflow: "hidden",
            }}
          >
            <motion.img
              src={left}
              variants={leftVariants_mobile}
              style={{ width: "23vw", transform: "rotate(120deg)" }}
            />
          </div>
          <motion.p
            style={{
              marginTop: "40px",
              marginLeft: "-40px",
              fontWeight: "bold",
              fontSize: "12px",
            }}
            variants={downpVariants}
          >
            QRコードをスキャンして予約
          </motion.p>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 465px) and (max-width: 600px)">
        <div style={div_style_mobile}>
          <motion.p
            style={{
              marginTop: "-60px",
              marginRight: "-60px",
              fontWeight: "bold",
              fontSize: "16px",
            }}
            variants={uppVariants}
          >
            カシェットのQRコードを発行
          </motion.p>
          <div
            style={{
              width: "25vw",
              transform: "rotate(120deg)",
              marginRight: "-50px",
              overflow: "hidden",
            }}
          >
            <motion.img
              src={right}
              variants={rightVariants_mobile}
              style={{ width: "23vw", transform: "rotate(120deg)" }}
            />
          </div>
          <div
            style={{
              width: "25vw",
              transform: "rotate(120deg)",
              marginLeft: "-50px",
              overflow: "hidden",
            }}
          >
            <motion.img
              src={left}
              variants={leftVariants_mobile}
              style={{ width: "23vw", transform: "rotate(120deg)" }}
            />
          </div>
          <motion.p
            style={{
              marginTop: "60px",
              marginLeft: "-60px",
              fontWeight: "bold",
              fontSize: "16px",
            }}
            variants={downpVariants}
          >
            QRコードをスキャンして予約
          </motion.p>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 601px) and (max-width: 650px)">
        <div style={div_style_mobile}>
          <motion.p
            style={{
              marginTop: "-70px",
              marginRight: "-70px",
              fontWeight: "bold",
            }}
            variants={uppVariants}
          >
            カシェットのQRコードを発行
          </motion.p>
          <div
            style={{
              width: "25vw",
              transform: "rotate(120deg)",
              marginRight: "-60px",
              overflow: "hidden",
            }}
          >
            <motion.img
              src={right}
              variants={rightVariants_mobile}
              style={{ width: "23vw", transform: "rotate(120deg)" }}
            />
          </div>
          <div
            style={{
              width: "25vw",
              transform: "rotate(120deg)",
              marginLeft: "-60px",
              overflow: "hidden",
            }}
          >
            <motion.img
              src={left}
              variants={leftVariants_mobile}
              style={{ width: "23vw", transform: "rotate(120deg)" }}
            />
          </div>
          <motion.p
            style={{
              marginTop: "70px",
              marginLeft: "-70px",
              fontWeight: "bold",
            }}
            variants={downpVariants}
          >
            QRコードをスキャンして予約
          </motion.p>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 651px) and (max-width: 1000px)">
        <div style={div_style_pc_A}>
          <motion.p style={{ fontWeight: "bold" }} variants={uppVariants}>
            カシェットのQRコードを発行
          </motion.p>
          <div style={img_style_pc}>
            <motion.img
              src={left}
              variants={leftVariants_pc}
              style={img_style_pc}
            />
          </div>
          <br />
          <br />
          <br />
          <div style={img_style_pc}>
            <motion.img
              src={right}
              variants={rightVariants_pc}
              style={img_style_pc}
            />
          </div>
          <motion.p style={{ fontWeight: "bold" }} variants={downpVariants}>
            QRコードをスキャンして予約
          </motion.p>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 1001px)">
        <div style={div_style_pc_S}>
          <motion.p style={{ fontWeight: "bold" }} variants={uppVariants}>
            カシェットのQRコードを発行
          </motion.p>
          <div style={img_style_pc}>
            <motion.img
              src={left}
              variants={leftVariants_pc}
              style={img_style_pc}
            />
          </div>
          <br />
          <br />
          <br />
          <br />
          <div style={img_style_pc}>
            <motion.img
              src={right}
              variants={rightVariants_pc}
              style={img_style_pc}
            />
          </div>
          <motion.p style={{ fontWeight: "bold" }} variants={downpVariants}>
            QRコードをスキャンして予約
          </motion.p>
        </div>
      </MediaQuery>
    </motion.section>
  );
  // }
};
export default Arrow;
