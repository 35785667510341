import React, {Component} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import reserve from '../static/reserve.svg';
import ReserveCardText from './ReserveCardText';

export default class ReserveCard extends Component {

    div_style_pc_S = {
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "30px",
        width: "950px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
        marginBottom: "2vw"
    } as React.CSSProperties;

    div_style_pc_A = {
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "30px",
        width: "85vw",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
        marginBottom: "2vw"
    } as React.CSSProperties;

    div_style_mobile = {
        backgroundColor: "white",
        borderRadius: "3vw",
        padding: "2vw",
        width: "72vw",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
    } as React.CSSProperties;

    img_style_pc_S = {
        width: "300px",
        height: "300px",
        display: "inline-block",
        verticalAlign: "middle",
        margin: "auto"
    } as React.CSSProperties;

    img_style_pc_A = {
        width: "28vw",
        height: "28vw",
        display: "inline-block",
        verticalAlign: "middle",
        margin: "auto"
    } as React.CSSProperties;

    img_style_mobile = {
        width: "30vw",
        marginTop: "1vw",
        marginBottom: "1vw",
        height: "30vw",
        display: "inline-block",
    } as React.CSSProperties;

    div_style_text_pc = {
        display: "inline-block",
        verticalAlign: "middle",
        margin: "auto"
    } as React.CSSProperties;

    render() {
        return (
            <div>
                <MediaQuery query="(max-width: 650px)">
                    <div style={this.div_style_mobile}>
                        <img src={reserve} style={this.img_style_mobile}/>
                        <ReserveCardText title="QRコードで予約" text="カシェットの予約は公開されたQRコードを読み取って行います。検索はできません。"/>
                        <ReserveCardText title="予約は承認制" text="カシェットの予約は全て承認制となっております。承認状況の確認は予約履歴ページから確認できます。"/>
                        <ReserveCardText title="簡単に再予約" text="一度予約したカシェットはホーム画面に表示されます。次回以降QRコードを読み取らずにホーム画面から選択して予約ができます。"/>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 651px) and (max-width: 1050px)">
                    <div style={this.div_style_pc_A}>
                        <img src={reserve} style={this.img_style_pc_A}/>
                        <div style={this.div_style_text_pc}>
                            <ReserveCardText title="QRコードで予約" text="カシェットの予約は公開されたQRコードを読み取って行います。検索はできません。"/>
                            <ReserveCardText title="予約は承認制" text="カシェットの予約は全て承認制となっております。承認状況の確認は予約履歴ページから確認できます。"/>
                            <ReserveCardText title="簡単に再予約" text="一度予約したカシェットはホーム画面に表示されます。次回以降QRコードを読み取らずにホーム画面から選択して予約ができます。"/>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 1051px)">
                    <div style={this.div_style_pc_S}>
                        <img src={reserve} style={this.img_style_pc_S}/>
                        <div style={this.div_style_text_pc}>
                            <ReserveCardText title="QRコードで予約" text="カシェットの予約は公開されたQRコードを読み取って行います。検索はできません。"/>
                            <ReserveCardText title="予約は承認制" text="カシェットの予約は全て承認制となっております。承認状況の確認は予約履歴ページから確認できます。"/>
                            <ReserveCardText title="簡単に再予約" text="一度予約したカシェットはホーム画面に表示されます。次回以降QRコードを読み取らずにホーム画面から選択して予約ができます。"/>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}