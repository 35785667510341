import React, { Component, useEffect } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import icon from "../static/icon.svg";
import AppStore from "../static/AppStore.svg";
import imgMake from "../static/imgMake.png";
import imgReserve from "../static/imgReserve.png";
import { motion, useAnimation, Variants } from "framer-motion";
import { useInView } from "react-intersection-observer";

const Invite = () => {
  /* ------------------- アニメーション系 ------------------- */
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const Variants1 = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
    },
  } as Variants;

  /* ------------------- アニメーション系 ------------------- */

  /* ------------------------ UI系 ------------------------ */
  const div_style = {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    textAlign: "center",
    alignItems: "center",
    paddingTop: "2vw",
  } as React.CSSProperties;

  const div_style_pc = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    marginBottom: "30px",
  } as React.CSSProperties;

  const img_style_pc_S = {
    width: "200px",
    height: "200px",
  } as React.CSSProperties;

  const img_style_pc_A = {
    width: "20vw",
    height: "20vw",
  } as React.CSSProperties;

  const img_style_disp_pc_S = {
    width: "250px",
    margin: "20px",
  } as React.CSSProperties;

  const img_style_disp_pc_A = {
    width: "20vw",
    margin: "10px",
  } as React.CSSProperties;

  const img_style_mobile = {
    width: "30vw",
    height: "30vw",
  } as React.CSSProperties;

  const div_style_badge = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  } as React.CSSProperties;
  /* ------------------------ UI系 ------------------------ */

  return (
    <motion.section ref={ref} animate={controls} style={div_style}>
      <MediaQuery query="(max-width: 800px)">
        <h4>cachetteをはじめてみませんか？</h4>
        <motion.img variants={Variants1} src={icon} style={img_style_mobile} />
        <div style={div_style_badge}>
          <a href="https://apps.apple.com/us/app/cachette/id1565488450">
            <img
              style={{
                marginTop: "3vw",
                height: "10vw",
                marginBottom: "5vw",
                marginLeft: "4vw",
              }}
              src={AppStore}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=jp.JShiota.cachette">
            <img
              style={{
                marginTop: "3vw",
                height: "15vw",
                marginBottom: "5vw",
                marginLeft: "3vw",
              }}
              alt="Google Play で手に入れよう"
              src="https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png"
            />
          </a>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 801px) and (max-width: 1050px)">
        <div style={div_style_pc}>
          <div>
            <motion.img
              variants={Variants1}
              style={img_style_disp_pc_A}
              src={imgMake}
            />
            <motion.img
              variants={Variants1}
              style={img_style_disp_pc_A}
              src={imgReserve}
            />
          </div>
          <div style={{ marginLeft: "30px" }}>
            <h4>cachetteをはじめてみませんか？</h4>
            <motion.img
              variants={Variants1}
              src={icon}
              style={img_style_pc_A}
            />
            <div style={div_style_badge}>
              <a href="https://apps.apple.com/us/app/cachette/id1565488450">
                <img
                  style={{
                    marginTop: "3vw",
                    height: "7vw",
                    marginBottom: "5vw",
                    marginLeft: "2vw",
                  }}
                  src={AppStore}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=jp.JShiota.cachette">
                <img
                  style={{
                    marginTop: "3vw",
                    height: "10vw",
                    marginBottom: "5vw",
                    marginLeft: "2vw",
                  }}
                  alt="Google Play で手に入れよう"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery query="(min-width: 1051px)">
        <div style={div_style_pc}>
          <div>
            <motion.img
              variants={Variants1}
              style={img_style_disp_pc_S}
              src={imgMake}
            />
            <motion.img
              variants={Variants1}
              style={img_style_disp_pc_S}
              src={imgReserve}
            />
          </div>
          <div style={{ marginLeft: "30px" }}>
            <h4>cachetteをはじめてみませんか？</h4>
            <motion.img
              variants={Variants1}
              src={icon}
              style={img_style_pc_S}
            />
            <div style={div_style_badge}>
              <a href="https://apps.apple.com/us/app/cachette/id1565488450">
                <img
                  style={{
                    marginTop: "5px",
                    height: "60px",
                    marginBottom: "5vw",
                    marginLeft: "35px",
                  }}
                  src={AppStore}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=jp.JShiota.cachette">
                <img
                  style={{
                    marginTop: "5px",
                    height: "90px",
                    marginBottom: "5vw",
                    marginLeft: "10px",
                  }}
                  alt="Google Play で手に入れよう"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/ja_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </div>
      </MediaQuery>
    </motion.section>
  );
};
export default Invite;
