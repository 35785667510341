import React, {Component} from 'react';
import Title from './TItle';
import TopImage from './TopImage';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import AppButton from './AppButton';

export default class Top extends Component {

    div_style_pc_S = {
        position: "relative",
        height: "700px",
        backgroundColor: '#fff',
        paddingBottom: "110px",
        textAlign: "center"
    } as React.CSSProperties;

    div_style_pc_A = {
        position: "relative",
        height: "700px",
        backgroundColor: '#fff',
        paddingBottom: "110px",
        textAlign: "center"
    } as React.CSSProperties;

    div_style_pc_B = {
        position: "relative",
        height: "600px",
        backgroundColor: '#fff',
        paddingBottom: "110px",
        textAlign: "center"
    } as React.CSSProperties;

    div_style_pc_C = {
        position: "relative",
        height: "500px",
        backgroundColor: '#fff',
        paddingBottom: "110px",
        textAlign: "center"
    } as React.CSSProperties;

    div_style_mobile = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: '#fff',
        paddingTop: "5vw",
    } as React.CSSProperties;

    render() {
        return (
            <div>
                <MediaQuery query="(max-width: 800px)">
                    <div style={this.div_style_mobile}>
                        <Title/>
                        <TopImage/>
                        <AppButton/>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 801px) and (max-width: 950px)">
                    <div style={this.div_style_pc_C}>
                        <div style={{position: "absolute", right: "5%"}}>
                            <TopImage/>
                        </div>
                        <div style={{textAlign: "left", position: "absolute", left: "5%"}}>
                            <Title/>
                            <AppButton/>
                        </div>
                        <div className="arrow1">
                            <span></span>
                        </div>
                        <div className="arrow2">
                            <span></span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 951px) and (max-width: 1060px)">
                    <div style={this.div_style_pc_B}>
                        <div style={{position: "absolute", right: "5%"}}>
                            <TopImage/>
                        </div>
                        <div style={{textAlign: "left", position: "absolute", left: "5%"}}>
                            <Title/>
                            <AppButton/>
                        </div>
                        <div className="arrow1">
                            <span></span>
                        </div>
                        <div className="arrow2">
                            <span></span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 1061px) and (max-width: 1300px)">
                    <div style={this.div_style_pc_A}>
                        <div style={{position: "absolute", right: "5%"}}>
                            <TopImage/>
                        </div>
                        <div style={{textAlign: "left", position: "absolute", left: "5%"}}>
                            <Title/>
                            <AppButton/>
                        </div>
                        <div className="arrow1">
                            <span></span>
                        </div>
                        <div className="arrow2">
                            <span></span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 1301px) and (max-width: 1439px)">
                    <div style={this.div_style_pc_A}>
                        <div style={{position: "absolute", right: "10%"}}>
                            <TopImage/>
                        </div>
                        <div style={{textAlign: "left", position: "absolute", left: "10%"}}>
                            <Title/>
                            <AppButton/>
                        </div>
                        <div className="arrow1">
                            <span></span>
                        </div>
                        <div className="arrow2">
                            <span></span>
                        </div>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 1440px)">
                    <div style={this.div_style_pc_S}>
                        <div style={{position: "absolute", right: "15%"}}>
                            <TopImage/>
                        </div>
                        <div style={{textAlign: "left", position: "absolute", left: "15%"}}>
                            <Title/>
                            <AppButton/>
                        </div>
                        <div className="arrow1">
                            <span></span>
                        </div>
                        <div className="arrow2">
                            <span></span>
                        </div>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}