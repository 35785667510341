import React, {Component} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';

export default class Header extends Component {

    header_style_S = {
        textAlign: "right",
        backgroundColor: "white",
        padding: "30px"
    } as React.CSSProperties;

    header_style_A = {
        textAlign: "right",
        backgroundColor: "white",
        padding: "30px 10px 30px 30px"
    } as React.CSSProperties;

    a_style = {
        marginRight: "50px",
        color: "#6D6C67",
        textDecoration: "none",
        fontSize: "20px",
        cursor: "pointer"
    } as React.CSSProperties;

    render() {
        return (
            <div>
                <MediaQuery query="(min-width: 801px) and (max-width: 1300px)">
                    <header style={this.header_style_A}>      
                        <a style={this.a_style} onClick={() => this.scroll("1")}>cachetteとは？</a>
                        <a style={this.a_style} onClick={() => this.scroll("2")}>機能</a>
                        <a style={this.a_style} onClick={() => this.scroll("3")}>利用シーン</a>          
                    </header>
                </MediaQuery>
                <MediaQuery query="(min-width: 1301px)">
                    <header style={this.header_style_S}>      
                        <a style={this.a_style} onClick={() => this.scroll("1")}>cachetteとは？</a>
                        <a style={this.a_style} onClick={() => this.scroll("2")}>機能</a>
                        <a style={this.a_style} onClick={() => this.scroll("3")}>利用シーン</a>          
                    </header>
                </MediaQuery>
            </div>
            
        );
    }

    scroll(name: string) {
        const element = document.getElementById(name);
        const scrollPosition = element!.getBoundingClientRect().top;
        window.scrollTo(0,scrollPosition);
    }
}