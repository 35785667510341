import React, {Component} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import ReserveCardText from './ReserveCardText';

interface Props {
    img: string,
    title: string,
    text: string
}
interface State {}
export default class UseCard extends Component<Props, State> {

    div_style_pc_S = {
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "20px",
        width: "950px",
        display: "flex",
        flexDirection: "row",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
        marginBottom: "2vw"
    } as React.CSSProperties;

    div_style_pc_A = {
        backgroundColor: "white",
        borderRadius: "20px",
        padding: "20px",
        width: "85vw",
        display: "flex",
        flexDirection: "row",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
        marginBottom: "2vw",
        alignItems: "center",
    } as React.CSSProperties;

    div_style_mobile = {
        backgroundColor: "white",
        borderRadius: "3vw",
        padding: "2vw",
        width: "72vw",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
        marginTop: "2vw",
        marginBottom: "3vw",
    } as React.CSSProperties;

    img_style_pc_S = {
        width: "80px",
        height: "80px",
        marginTop: "10px"
    } as React.CSSProperties;

    img_style_pc_A = {
        width: "80px",
        height: "80px"
    } as React.CSSProperties;

    render() {
        return (
            <div>
                <MediaQuery query="(max-width: 650px)">
                    <div style={this.div_style_mobile}>
                        <img style={this.img_style_pc_S} src={this.props.img}/>
                        <ReserveCardText title={this.props.title} text={this.props.text}/>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 651px) and (max-width: 1050px)">
                    <div style={this.div_style_pc_A}>
                        <img style={this.img_style_pc_A} src={this.props.img}/>
                        <ReserveCardText title={this.props.title} text={this.props.text}/>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 1051px)">
                    <div style={this.div_style_pc_S}>
                        <img style={this.img_style_pc_S} src={this.props.img}/>
                        <ReserveCardText title={this.props.title} text={this.props.text}/>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}