import React, {Component} from 'react';
import topimage from '../static/topimage.svg';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { motion, useAnimation, Variants } from 'framer-motion';

export default class TopImage extends Component {

    img_style_pc_S = {
        width: "700px",
        marginTop: "50px",
    } as React.CSSProperties;

    img_style_pc_A = {
        width: "600px",
        marginTop: "50px",
    } as React.CSSProperties;

    img_style_pc_B = {
        width: "500px",
        marginTop: "50px",
    } as React.CSSProperties;

    img_style_mobile = {
        width: "70vw",
    } as React.CSSProperties;

    render() {
        return (
            <div>
                <MediaQuery query="(max-width: 800px)">
                    <motion.img 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.6}}
                    src={topimage} style={this.img_style_mobile}/>
                </MediaQuery>
                <MediaQuery query="(min-width: 801px) and (max-width: 950px)">
                    <motion.img 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.9}}
                    src={topimage} style={this.img_style_pc_B}/>
                </MediaQuery>
                <MediaQuery query="(min-width: 951px) and (max-width: 1060px)">
                    <motion.img 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.9}}
                    src={topimage} style={this.img_style_pc_A}/>
                </MediaQuery>
                <MediaQuery query="(min-width: 1061px)">
                    <motion.img 
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.9}}
                    src={topimage} style={this.img_style_pc_S}/>
                </MediaQuery>
            </div>
        );
    }
}