import React, {Component} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';

interface Props {
    title: string,
    text: string
}
interface State {}
export default class ReserveCardText extends Component<Props, State> {

    div_style_pc = {
        // width: "45vw",
        textAlign: "left",
        marginLeft: "40px",
        marginBottom: "20px",
        marginTop: "20px"
    } as React.CSSProperties;

    div_style_mobile = {
        width: "60vw",
        textAlign: "left",
        marginLeft: "2vw",
        marginBottom: "3vw",
        marginTop: "3vw"
    } as React.CSSProperties;

    h4_style_pc = {
        margin: "auto",
        marginBottom: "-5px"
    } as React.CSSProperties;

    h4_style_mobile = {
        margin: "auto",
        // fontSize: "5vw",
        marginBottom: "-5px"
    }

    p_style_pc = {
        marginBottom: "0"
    } as React.CSSProperties;

    p_style_mobile = {
        // fontSize: "3.5vw",
        marginBottom: "0"
    }

    render() {
        return (
            <div>
                <MediaQuery query="(max-width: 650px)">
                    <div style={this.div_style_mobile}>
                        <h4 style={this.h4_style_mobile}>{this.props.title}</h4>
                        <p style={this.p_style_mobile}>{this.props.text}</p>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 651px)">
                    <div style={this.div_style_pc}>
                        <h4 style={this.h4_style_pc}>{this.props.title}</h4>
                        <p style={this.p_style_pc}>{this.props.text}</p>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}