import React, {Component} from 'react';

export default class Home extends Component {

    div_style = {
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0 1.0875rem 1.45rem`
    } as React.CSSProperties;

    render() {
        return (
            <div style={this.div_style}>
                <br/>
                <h1>cachette利用規約・プライバシーポリシー</h1>

                <h3>1.はじめに</h3>
                <p>cachette（以下「当サービス」）は、スマートフォン用アプリケーション（以下「アプリ」）を開発・運用しています。本アプリのご使用によって、本規約に同意していただいたものとみなします。</p>
                
                <h3>2.収集する情報</h3>
                <p>アプリのご利用に際して、以下の利用者情報を取得いたします。</p>
                
                <h3>2.1 アプリケーションの利用状況の収集</h3>
                <p>当サービスが配信するアプリでは、利用状況解析のためにGoogle Firebase Analyticsを使用する場合がございます。<br />
                取得する情報、利用目的、第三者への提供等の詳細につきましては、以下のプライバシーポリシーのリンクよりご確認ください。</p>
                <a href="https://policies.google.com/privacy?hl=ja" target="_blank" rel="noopener noreferrer">Firebase Analytics（Google Inc.)</a>

                <br/>
                <br/>
                <h3>2.2 個人情報の収集</h3>
                <p>当サービスは、利用登録の際にメールアドレスをお尋ねすることがあり、本規約に同意の上、利用登録が完了するものとします。</p>

                <h3>3.利用目的</h3>
                <p>当サービスが個人情報を収集・利用する目的は，以下のとおりです。</p>
                <ol>
                    <li>当サービスの提供・運営のため</li>
                    <li>利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため</li>
                    <li>ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため</li>
                    <li>上記の利用目的に付随する目的</li>
                </ol>

                <h3>4. 個人情報の管理</h3>
                <p>当サービスは、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを防止するため、安全対策を実施し個人情報の厳重な管理を行ないます。</p>

                <h3>5. 個人情報の第三者への開示・提供の禁止</h3>
                <p>当サービスは、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に開示いたしません。</p>
                <li>お客さまの同意がある場合</li>
                <li>法令に基づき開示することが必要である場合</li>

                <h3>6. 法令、規範の遵守と見直し</h3>
                <p>当サービスは、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、本ポリシーの内容を適宜見直し、その改善に努めます。</p>

                <h3>7. 免責事項</h3>
                <p>本アプリがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、および不具合が生じないことについて、何ら保証するものではありません。<br />
                当サービスの都合によりアプリの仕様を変更できます。私たちは、本アプリの提供の終了、変更、または利用不能、本アプリの利用によるデータの消失または機械の故障もしくは損傷、その他本アプリに関してユーザーが被った損害につき、賠償する責任を一切負わないものとします。</p>

                <h3>8. 禁止事項</h3>
                <p>ユーザーは，当サービスの利用にあたり，以下の行為をしてはなりません。</p>
                <li>法令または公序良俗に違反する行為</li>
                <li>犯罪行為に関連する行為</li>
                <li>当サービスの内容等、当サービスに含まれる著作権、商標権ほか知的財産権を侵害する行為</li>
                <li>ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
                <li>当サービスによって得られた情報を商業的に利用する行為</li>
                <li>当サービスの運営を妨害するおそれのある行為</li>
                <li>不正アクセスをし，またはこれを試みる行為</li>
                <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
                <li>不正な目的を持って当サービスを利用する行為</li>
                <li>当サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</li>
                <li>他のユーザーに成りすます行為</li>
                <li>面識のない異性との出会いを目的とした行為</li>
                <li>当サービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
                <li>その他，当サービスが不適切と判断する行為</li>
                <br/>
                <h3>9. 著作権・知的財産権等</h3>
                <p>著作権その他一切の権利は、当サービス又は権利を有する第三者に帰属します。</p>

                <h3>10. 連絡先</h3>
                <a href="mailto:cachette.app@gmail.com" target="_blank" rel="noopener noreferrer">お問い合わせ先</a>
                </div>
        );
    }
}