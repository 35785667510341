import React, {Component} from 'react';
import Facebook from '../static/Facebook.svg'
import Twitter from '../static/Twitter.svg'
import note from '../static/note.svg'
import LINE from '../static/LINE.svg'
import Copy from '../static/Copy.svg'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast, Slide, Flip, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MediaQuery, { useMediaQuery } from 'react-responsive';

export default class Footer extends Component {

    footer_style = {
        textAlign: "center",
        backgroundColor: "#6D6C67",
        height: "100%",
        paddingBottom: "25px"
    } as React.CSSProperties;

    div_style_mobile = {
        paddingTop: "2vw",
        height: "8vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties;

    div_style_text_mobile = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "35vw"
    } as React.CSSProperties;

    div_style_sns_mobile = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "55vw",
    } as React.CSSProperties;

    p_style_mobile = {
        fontSize: "2vw",
        color: "white",
        textDecoration: "none"
    } as React.CSSProperties;

    img_style_mobile = {
        width: "6vw",
        height: "6vw",
        cursor: "pointer"
    } as React.CSSProperties;

    p_style_copyright_mobile = {
        fontSize: "2vw",
        marginTop: "0px",
        color: "white"
    } as React.CSSProperties;

    div_style_pc = {
        paddingTop: "20px",
        height: "60px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    } as React.CSSProperties;

    div_style_text_pc = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "35vw"
    } as React.CSSProperties;

    div_style_sns_pc = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        width: "55vw",
    } as React.CSSProperties;

    p_style_pc = {
        fontSize: "20px",
        color: "white",
        textDecoration: "none"
    } as React.CSSProperties;

    img_style_pc = {
        width: "50px",
        height: "50px",
        cursor: "pointer"
    } as React.CSSProperties;

    p_style_copyright_pc = {
        marginTop: "0px",
        color: "white"
    } as React.CSSProperties;



    render() {
        const notify = () => toast.dark(<div style={{textAlign:"center"}}><p style={{color: 'white', fontSize: "15px"}}>リンクをコピーしました</p></div>, 
        {
            position: "bottom-right",
        });

        return (
            <footer style={this.footer_style}>
                <MediaQuery query="(max-width: 800px)">
                    <div style={this.div_style_mobile}>
                        <div style={this.div_style_text_mobile}>
                            <a style={this.p_style_mobile} href="/policy" target="_blank" rel="noopener noreferrer">利用規約</a>
                            <a style={this.p_style_mobile} href="mailto:cachette.app@gmail.com" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
                        </div>
                        <div style={this.div_style_sns_mobile}>
                            <a href="http://www.facebook.com/share.php?u=https://cachette000.web.app/" 
                                rel="noopener noreferrer" 
                                target="_blank">
                                    <img src={Facebook} style={this.img_style_mobile}/>
                            </a>
                            <a href="https://twitter.com/share?url=https://cachette000.web.app&hashtags=cachette&text=cachette %7C お店の出店・予約管理をカンタンに！" 
                                rel="noopener noreferrer" 
                                target="_blank">
                                    <img src={Twitter} style={this.img_style_mobile}/>
                            </a>
                            <a href="https://note.com/intent/post?url=https://cachette000.web.app/&hashtags=cachette" 
                                rel="noopener noreferrer" 
                                target="_blank">
                                    <img src={note} style={this.img_style_mobile}/>
                            </a>
                            <a href="https://social-plugins.line.me/lineit/share?url=https://cachette000.web.app/" 
                                rel="noopener noreferrer" 
                                target="_blank">
                                    <img src={LINE} style={this.img_style_mobile}/>
                            </a>
                            <CopyToClipboard
                                text={"https://cachette000.web.app/"}
                                onCopy={notify}
                                >
                                <img src={Copy} style={this.img_style_mobile}/>
                            </CopyToClipboard>
                            <ToastContainer
                                position="bottom-center"
                                hideProgressBar={true}
                                autoClose={1000}
                                closeButton={false}
                                />
                        </div>
                    </div>
                    <br/>
                    <p style={this.p_style_copyright_mobile}>©︎ 2021 Junya Shiota</p>
                </MediaQuery>
                <MediaQuery query="(min-width: 801px)">
                    <div style={this.div_style_pc}>
                        <div style={this.div_style_text_pc}>
                            <a style={this.p_style_pc} href="/policy" target="_blank" rel="noopener noreferrer">利用規約</a>
                            <a style={this.p_style_pc} href="mailto:cachette.app@gmail.com" target="_blank" rel="noopener noreferrer">お問い合わせ</a>
                        </div>
                        <div style={this.div_style_sns_pc}>
                            <a href="http://www.facebook.com/share.php?u=https://cachette000.web.app/" 
                                rel="noopener noreferrer" 
                                target="_blank">
                                    <img src={Facebook} style={this.img_style_pc}/>
                            </a>
                            <a href="https://twitter.com/share?url=https://cachette000.web.app&hashtags=cachette&text=cachette %7C お店の出店・予約管理をカンタンに！" 
                                rel="noopener noreferrer" 
                                target="_blank">
                                    <img src={Twitter} style={this.img_style_pc}/>
                            </a>
                            <a href="https://note.com/intent/post?url=https://cachette000.web.app/&hashtags=cachette" 
                                rel="noopener noreferrer" 
                                target="_blank">
                                    <img src={note} style={this.img_style_pc}/>
                            </a>
                            <a href="https://social-plugins.line.me/lineit/share?url=https://cachette000.web.app/" 
                                rel="noopener noreferrer" 
                                target="_blank">
                                    <img src={LINE} style={this.img_style_pc}/>
                            </a>
                            <CopyToClipboard
                                text={"https://cachette000.web.app/"}
                                onCopy={notify}
                                >
                                <img src={Copy} style={this.img_style_pc}/>
                            </CopyToClipboard>
                            <ToastContainer
                                position="bottom-center"
                                hideProgressBar={true}
                                autoClose={1000}
                                closeButton={false}
                                />
                        </div>
                    </div>
                    <br/>
                    <small style={this.p_style_copyright_pc}>©︎ 2021 Junya Shiota</small>
                </MediaQuery>
            </footer>
        );
    }
}