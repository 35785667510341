import React, { Component, useEffect } from "react";
import MediaQuery, { useMediaQuery } from "react-responsive";
import OpenCard from "./OpenCard";
import anyoneOpen from "../static/anyoneOpen.svg";
import easyReserve from "../static/easyReserve.svg";
import shareSNS from "../static/shareSNS.svg";
import { motion, useAnimation, Variants } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Reserve from "./Reserve";

const Open = () => {
  /* ------------------- アニメーション系 ------------------- */
  const controls = useAnimation();
  const { ref, inView } = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
    if (!inView) {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const Variants1 = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: 0.3,
      },
    },
  } as Variants;

  const Variants2 = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: 0.6,
      },
    },
  } as Variants;

  const Variants3 = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: 0.9,
      },
    },
  } as Variants;

  const Variants4 = {
    hidden: {
      opacity: 0,
      y: 10,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        delay: 1.2,
      },
    },
  } as Variants;
  /* ------------------- アニメーション系 ------------------- */

  /* ------------------------ UI系 ------------------------ */
  const div_style = {
    textAlign: "center",
  } as React.CSSProperties;

  const div_style_card_pc = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  } as React.CSSProperties;

  const div_style_card_mobile = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "4vw",
  } as React.CSSProperties;
  /* ------------------------ UI系 ------------------------ */

  return (
    <motion.section ref={ref} animate={controls} style={div_style} id="2">
      <MediaQuery query="(max-width: 650px)">
        <h3>機能</h3>
        <div style={div_style_card_mobile}>
          <motion.div variants={Variants1}>
            <OpenCard
              title="誰でも出店"
              img={anyoneOpen}
              text="手数料、条件等は一切ございません。実際にお店を持っていないという方でも出店可能です。"
            />
          </motion.div>
          <motion.div variants={Variants2}>
            <OpenCard
              title="予約を簡単確認"
              img={easyReserve}
              text="一覧またはカレンダー形式で予約を確認することができます。予約は通知でお知らせします。"
            />
          </motion.div>
          <motion.div variants={Variants3}>
            <OpenCard
              title="SNS共有"
              img={shareSNS}
              text="お店の予約用QRコードを出力できます。SNS等で共有してお店の予約をすることができます。"
            />
          </motion.div>
        </div>
        <motion.div variants={Variants4}>
          <Reserve />
        </motion.div>
      </MediaQuery>
      <MediaQuery query="(min-width: 651px)">
        <h3 style={{ marginTop: "10px" }}>機能</h3>
        <div style={div_style_card_pc}>
          <motion.div variants={Variants1}>
            <OpenCard
              title="誰でも出店"
              img={anyoneOpen}
              text="手数料、条件等は一切ございません。実際にお店を持っていないという方でも出店可能です。"
            />
          </motion.div>
          <motion.div variants={Variants2}>
            <OpenCard
              title="予約を簡単確認"
              img={easyReserve}
              text="一覧またはカレンダー形式で予約を確認することができます。予約は通知でお知らせします。"
            />
          </motion.div>
          <motion.div variants={Variants3}>
            <OpenCard
              title="SNS共有"
              img={shareSNS}
              text="お店の予約用QRコードを出力できます。SNS等で共有してお店の予約をすることができます。"
            />
          </motion.div>
        </div>
        <br />
        <br />
        <motion.div variants={Variants4}>
          <Reserve />
        </motion.div>
      </MediaQuery>
    </motion.section>
  );
};

export default Open;
