import React, {Component} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';

interface Props {
    title: string,
    img: string,
    text: string
}
interface State {}
export default class OpenCard extends Component<Props, State> {

    div_style_pc_S = {
        textAlign: "center",
        backgroundColor: "white",
        borderRadius: "20px",
        display: "inline-block",
        padding: "30px",
        margin: "20px",
        width: "250px",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
    } as React.CSSProperties;

    div_style_pc_A = {
        textAlign: "center",
        backgroundColor: "white",
        borderRadius: "2vw",
        display: "inline-block",
        padding: "20px",
        margin: "1vw",
        width: "25vw",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
    } as React.CSSProperties;

    div_style_mobile = {
        textAlign: "center",
        backgroundColor: "white",
        borderRadius: "3vw",
        display: "inline-block",
        padding: "5vw",
        width: "65vw",
        marginTop: "2vw",
        marginBottom: "3vw",
        filter: "drop-shadow(0 0 0.25rem #00000033)",
    } as React.CSSProperties;

    h4_style_pc = {
        margin: "auto",
    } as React.CSSProperties;

    h4_style_mobile = {
        margin: "auto"
    } as React.CSSProperties;

    img_style_pc_S = {
        width: "140px",
        marginTop: "5px",
        marginBottom: "5px",
        height: "140px"
    } as React.CSSProperties;

    img_style_pc_A = {
        width: "15vw",
        marginTop: "5px",
        marginBottom: "5px",
        height: "15vw"
    } as React.CSSProperties;

    img_style_mobile = {
        width: "30vw",
        marginBottom: "-2vw",
        height: "30vw"
    } as React.CSSProperties;

    p_style_mobile = {
        marginBottom: 0
    } as React.CSSProperties;

    render() {
        return (
            <div>
                <MediaQuery query="(max-width: 650px)">
                    <div style={this.div_style_mobile}>
                        <h4 style={this.h4_style_mobile}>{this.props.title}</h4>
                        <img src={this.props.img} style={this.img_style_mobile}/>
                        <p style={this.p_style_mobile}>{this.props.text}</p>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 651px) and (max-width: 1050px)">
                    <div style={this.div_style_pc_A}>
                        <h4 style={this.h4_style_pc}>{this.props.title}</h4>
                        <img src={this.props.img} style={this.img_style_pc_A}/>
                        <p >{this.props.text}</p>
                    </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 1051px)">
                    <div style={this.div_style_pc_S}>
                        <h4 style={this.h4_style_pc}>{this.props.title}</h4>
                        <img src={this.props.img} style={this.img_style_pc_S}/>
                        <p >{this.props.text}</p>
                    </div>
                </MediaQuery>
            </div>
        );
    }
}