import React, {Component} from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import title from '../static/title.svg'
import { motion, useAnimation, Variants } from 'framer-motion';

export default class Title extends Component {

    div_style_mobile = {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    } as React.CSSProperties;

    img_style_pc_S = {
        width: "550px",
        marginBottom: 0,
    } as React.CSSProperties;

    img_style_pc_A = {
        width: "450px",
        marginBottom: 0,
    } as React.CSSProperties;

    img_style_pc_B = {
        width: "350px",
        marginBottom: 0,
    } as React.CSSProperties;

    img_style_mobile = {
        width: "75vw",
        marginBottom: 0,
        marginTop: "2vw",
        fontFamily: "STHEITI"
    } as React.CSSProperties;

    p_style_mobile = {
        fontSize: "4.5vw",
    } as React.CSSProperties;

    render() {

        return (
            <div>
                <MediaQuery query="(max-width: 800px)">
                <div style={this.div_style_mobile}>
                    <motion.img 
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3}}
                            src={title}
                            style={this.img_style_mobile}/>
                    <motion.p
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.3}}
                    style={this.p_style_mobile}>お店の出店・予約管理をカンタンに</motion.p>
                </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 801px) and (max-width: 950px)">
                <div>
                    <div style={{textAlign: "left", marginTop: "100px"}}>
                        <motion.img 
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3}}
                            src={title}
                            style={this.img_style_pc_B}/>
                        <motion.h3
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.3}}
                            >お店の出店・予約管理をカンタンに</motion.h3>
                    </div>
                </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 951px) and (max-width: 1060px)">
                <div>
                    <div style={{textAlign: "left", marginTop: "100px"}}>
                        <motion.img 
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3}}
                            src={title}
                            style={this.img_style_pc_A}/>
                        <motion.h3
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.3}}
                            >お店の出店・予約管理をカンタンに</motion.h3>
                    </div>
                </div>
                </MediaQuery>
                <MediaQuery query="(min-width: 1061px)">
                <div>
                    <div style={{textAlign: "left", marginTop: "100px"}}>
                        <motion.img 
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3}}
                            src={title} 
                            style={this.img_style_pc_S}/>
                        <motion.h3
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.3, delay: 0.3}} 
                            >お店の出店・予約管理をカンタンに</motion.h3>
                    </div>
                </div>
                </MediaQuery>
            </div>
        );
    }
}